/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.container{
    width: 85%;
    margin: auto;
    padding: 15px;
  } 
.top-menu{
    padding: 5px 20px;
    //background-color: #f3f4f5;
    //background: #fc9303;
    background-color: rgb(213,67,39);
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;

    a{
        font-size: 0.85em;
        text-decoration: none;
        //color: rgba(49,53,59,0.68);
        color: #ffffff;
    }

    .right{
        a{
            padding: 0 10px;
        }
    }

    a:hover{
        color: yellow;
    }
}

.toolbar{
    display: flex;
    padding: 20px 20px;
    justify-content: space-between;
    background: #ffffff;
}

.flex{
    display: flex;
}

.input-search{
    border: none;
    width: 100%;
    padding: 12px 15px;
    border-radius: 10px;
    font-size: 0.85em;
}

.btn-daftar{
    --background: rgb(213,67,39);
}
.btn-login{
    --border-color: rgb(213,67,39);
    --color: rgb(213,67,39);
}

.ck-logo{
    border-radius: 5px;
}

.bg-gray{
    --background: #f3f4f5;
}

.bg-orange{
    --background: rgb(213,67,39);
    --color: #ffffff;
}

.color-orange{
    color: rgb(213,67,39);
}

ion-searchbar.input-round{
    --border-radius: 20px;
}

.form-search{
    border: solid 1px #dddddd;
    border-radius: 10px;
    background: #eeeeee;
}

.backdrop-result{
    position: fixed;
    background: rgba(0,0,0,0.15);
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    bottom: 0;
    top: 115px;
    z-index: 999;
}

.result{
    position: absolute;
    width: 100%;
    height: 500px;
    background:#ffffff;
    margin-top: 2px;
    z-index: 9999;
    border-radius: 0 0 10px 10px;
    padding: 10px 15px;
    
    .list{
        --color: #555555;
    }
}

.--hide{
    display: none;
}

.m-15{
    margin: 15px 0;
}
.p-8{
    padding-left: 8px;
}

ion-modal.full-height{
    --height: 100%;
    
}
@media screen and (max-width:768px){
   .top-menu, .desktop{
    display: none;
   }
   .form-search{
    width: 100%;
   }
   .toolbar{
    background: rgb(213,67,39);
   }
   .mobile{
        display: block;
    }
    .container{
        width: 100%;
    }
    .search-segment{
        background: var(--color-orange);
        box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.38);
        -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.38);
        -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.38);
        ion-segment-button{
            --color: rgba(255,255,255,.6);
            --color-checked: var(--color-white);
        }
    }
}
@media screen and (min-width:768px){
    ion-toolbar{
        --padding-top: 20px;
        --padding-bottom: 20px;
    }
    .mobile{
        display: none;
    }
    .form-search{
        width: 90%;
    }

    .modal-search{
        margin-top: 115px;
    }
    .search-segment{
        width: 300px;
    }
}